import styled from '@emotion/styled';
import { css } from '@emotion/react';

import LogoSignatureSVG from 'icons/logo-signature.svg';
import LogoTextSVG from 'icons/logo-text.svg';
import IconAimSVG from 'icons/icon-aim.svg';
import IconAIvoryLogoSVG from 'icons/icon-aivory-logo.svg';
import IconAnalytics1SVG from 'icons/icon-analytics-1.svg';
import IconAnalytics2SVG from 'icons/icon-analytics-2.svg';
import IconAnalyticsLineSVG from 'icons/icon-anayltics-line.svg';
import IconArrowDownSVG from 'icons/icon-arrow-down.svg';
import IconArrowLeftSVG from 'icons/icon-arrow-left.svg';
import IconArrowRightSVG from 'icons/icon-arrow-right.svg';
import IconArrowUpSVG from 'icons/icon-arrow-up.svg';
import IconCalendarSVG from 'icons/icon-calendar.svg';
import IconCheckCircleSVG from 'icons/icon-check-circle.svg';
import IconCheckSVG from 'icons/icon-check.svg';
import IconCheckboxOffSVG from 'icons/icon-checkbox-off.svg';
import IconCheckboxOnSVG from 'icons/icon-checkbox-on.svg';
import IconCloseSVG from 'icons/icon-close.svg';
import IconContentsAnalySVG from 'icons/icon-contents-analy.svg';
import IconDeleteSVG from 'icons/icon-delete.svg';
import IconDesktopSVG from 'icons/icon-desktop.svg';
import IconDevicesSVG from 'icons/icon-devices.svg';
import IconDownloadSVG from 'icons/icon-download.svg';
import IconErrorSVG from 'icons/icon-error.svg';
import IconEyeOffSVG from 'icons/icon-eye-off.svg';
import IconEyeOnSVG from 'icons/icon-eye-on.svg';
import IconFillArrowDownSVG from 'icons/icon-fill-arrow-down.svg';
import IconFillArrowUpSVG from 'icons/icon-fill-arrow-up.svg';
import IconFilterSVG from 'icons/icon-filter.svg';
import IconFlagSVG from 'icons/icon-flag.svg';
import IconGraph1SVG from 'icons/icon-graph-1.svg';
import IconHeatmapSVG from 'icons/icon-heatmap.svg';
import IconHelpSVG from 'icons/icon-help.svg';
import IconImgSVG from 'icons/icon-img.svg';
import IconLaunchSVG from 'icons/icon-launch.svg';
import IconLocationSVG from 'icons/icon-location.svg';
import IconLocktimeSVG from 'icons/icon-locktime.svg';
import IconMenuSVG from 'icons/icon-menu.svg';
import IconMoreSVG from 'icons/icon-more.svg';
import IconMultiChartSVG from 'icons/icon-multi-chart.svg';
import IconNonimgSVG from 'icons/icon-nonimg.svg';
import IconPageAnalySVG from 'icons/icon-page-analy.svg';
import IconPageviewSVG from 'icons/icon-pageview.svg';
import IconPaperSVG from 'icons/icon-paper.svg';
import IconPhoneSVG from 'icons/icon-phone.svg';
import IconPlusSVG from 'icons/icon-plus.svg';
import IconProfileSVG from 'icons/icon-profile.svg';
import IconSearchSVG from 'icons/icon-search.svg';
import IconServiceAnalySVG from 'icons/icon-service-analy.svg';
import IconSetting1SVG from 'icons/icon-setting-1.svg';
import IconSetting2SVG from 'icons/icon-setting-2.svg';
import IconSMArrowDownSVG from 'icons/icon-sm-arrow-down.svg';
import IconSMArrowLeftSVG from 'icons/icon-sm-arrow-left.svg';
import IconSMArrowRightSVG from 'icons/icon-sm-arrow-right.svg';
import IconSMArrowUpSVG from 'icons/icon-sm-arrow-up.svg';
import IconTabletSVG from 'icons/icon-tablet.svg';
import IconTextSearchSVG from 'icons/icon-text-search.svg';
import IconTimeSVG from 'icons/icon-time.svg';
import IconUserAnalySVG from 'icons/icon-user-analy.svg';
import IconUserflowSVG from 'icons/icon-userflow.svg';
import IconWarningSVG from 'icons/icon-warning.svg';
import IconWorldSVG from 'icons/icon-world.svg';
import IconWysiwygSVG from 'icons/icon-wysiwyg.svg';
import IconUPSVG from 'icons/icon-up.svg';
import IconDownSVG from 'icons/icon-down.svg';
import IconRadioSVG from 'icons/btn-radio-default.svg';
import IconBotSVG from 'icons/icon-bot.svg';
import IconABTestSVG from 'icons/icon-a-btest.svg';
import IconThumbSVG from 'icons/icon-thumb.svg';
import IconWorldWideSVG from 'icons/icon-worldwide.svg';
import IconFacesSVG from 'icons/icon-faces.svg';

import IconPublic1SVG from 'icons/icon-public-1.svg';
import IconPublic2SVG from 'icons/icon-public-2.svg';
import IconPublic3SVG from 'icons/icon-public-3.svg';
import IconPublic4SVG from 'icons/icon-public-4.svg';

import IconCorp1SVG from 'icons/icon-corp-1.svg';
import IconCorp2SVG from 'icons/icon-corp-2.svg';
import IconCorp3SVG from 'icons/icon-corp-3.svg';
import IconCorp4SVG from 'icons/icon-corp-4.svg';

import IconShop1SVG from 'icons/icon-shop-1.svg';
import IconShop2SVG from 'icons/icon-shop-2.svg';
import IconShop3SVG from 'icons/icon-shop-3.svg';
import IconShop4SVG from 'icons/icon-shop-4.svg';

import LogoPlaniSVG from 'icons/logo-plani.svg';

import IconHomeSVG from 'icons/icon-home.svg';

// export const IconError = styled(IconErrorSVG)``;
// export const IconArrowRight = styled(IconArrowRightSVG)``;
// export const IconCheckCircle = styled(IconCheckCircleSVG)``;
export const LogoSignature = styled(LogoSignatureSVG)``;
export const LogoText = styled(LogoTextSVG)``;
export const LogoPlani = styled(LogoPlaniSVG)``;

const focus_fill = css`
  fill: white;
`;

const disabled_fill = css`
  fill: #9e9e9e;
`;

const default_fill = css`
  fill: #b597f7;
`;
export const IconHome = styled(IconHomeSVG)`
  width: 20px;
  height: 17px;
`;

export const IconPublic1 = styled(IconPublic1SVG)``;
export const IconPublic2 = styled(IconPublic2SVG)``;
export const IconPublic3 = styled(IconPublic3SVG)``;
export const IconPublic4 = styled(IconPublic4SVG)``;

export const IconCorp1 = styled(IconCorp1SVG)``;
export const IconCorp2 = styled(IconCorp2SVG)``;
export const IconCorp3 = styled(IconCorp3SVG)``;
export const IconCorp4 = styled(IconCorp4SVG)``;

export const IconShop1 = styled(IconShop1SVG)``;
export const IconShop2 = styled(IconShop2SVG)``;
export const IconShop3 = styled(IconShop3SVG)``;
export const IconShop4 = styled(IconShop4SVG)``;

export const IconWorldWide = styled(IconWorldWideSVG)``;

export const IconThumb = styled(IconThumbSVG)``;

export const IconFaces = styled(IconFacesSVG)``;

export const IconAim = styled(IconAimSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconAIvoryLogo = styled(IconAIvoryLogoSVG)`
  g {
    path {
      fill: #7851e7;
    }
  }
  &.focus {
    g {
      path {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    g {
      path {
        ${disabled_fill};
      }
    }
  }
  &.default {
    g {
      path {
        ${default_fill};
      }
    }
  }
`;

export const IconAnalytics1 = styled(IconAnalytics1SVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconAnalytics2 = styled(IconAnalytics2SVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconAnalyticsLine = styled(IconAnalyticsLineSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconArrowDown = styled(IconArrowDownSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconArrowLeft = styled(IconArrowLeftSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconArrowRight = styled(IconArrowRightSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconArrowUp = styled(IconArrowUpSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconCalendar = styled(IconCalendarSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconCheckCircle = styled(IconCheckCircleSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconCheck = styled(IconCheckSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconCheckboxOff = styled(IconCheckboxOffSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconCheckboxOn = styled(IconCheckboxOnSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconClose = styled(IconCloseSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconContentsAnaly = styled(IconContentsAnalySVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconDelete = styled(IconDeleteSVG)`
  path {
    &:last-child {
      ${default_fill};
    }
  }
  &.focus {
    path {
      &:last-child {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    path {
      &:last-child {
        ${disabled_fill};
      }
    }
  }
  &.default {
    path {
      &:last-child {
        ${default_fill};
      }
    }
  }
`;

export const IconDesktop = styled(IconDesktopSVG)`
  path {
    &:last-child {
      ${default_fill};
    }
  }
  &.focus {
    path {
      &:last-child {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    path {
      &:last-child {
        ${disabled_fill};
      }
    }
  }
  &.default {
    path {
      &:last-child {
        ${default_fill};
      }
    }
  }
`;

export const IconDevices = styled(IconDevicesSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconDownload = styled(IconDownloadSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconError = styled(IconErrorSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconEyeOff = styled(IconEyeOffSVG)`
  g {
    path {
      ${default_fill};
    }
  }
  &.focus {
    g {
      path {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    g {
      path {
        ${disabled_fill};
      }
    }
  }
  &.default {
    g {
      path {
        ${default_fill};
      }
    }
  }
`;

export const IconEyeOn = styled(IconEyeOnSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconFillArrowDown = styled(IconFillArrowDownSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconFillArrowUp = styled(IconFillArrowUpSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconFilter = styled(IconFilterSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconFlag = styled(IconFlagSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconGraph1 = styled(IconGraph1SVG)`
  path {
    &:last-child {
      ${default_fill};
    }
  }
  &.focus {
    path {
      &:last-child {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    path {
      &:last-child {
        ${disabled_fill};
      }
    }
  }
  &.default {
    path {
      &:last-child {
        ${default_fill};
      }
    }
  }
`;

export const IconHeatmap = styled(IconHeatmapSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconHelp = styled(IconHelpSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconImg = styled(IconImgSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconLaunch = styled(IconLaunchSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconLocation = styled(IconLocationSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path,
    ellipse {
      ${focus_fill};
    }
  }
  &.disabled {
    path,
    ellipse {
      ${disabled_fill};
    }
  }
  &.default {
    path,
    ellipse {
      ${default_fill};
    }
  }
`;

export const IconLocktime = styled(IconLocktimeSVG)`
  g {
    path {
      ${default_fill};
    }
  }
  &.focus {
    g {
      path {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    g {
      path {
        ${disabled_fill};
      }
    }
  }
  &.default {
    g {
      path {
        ${default_fill};
      }
    }
  }
`;

export const IconMenu = styled(IconMenuSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconMore = styled(IconMoreSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconMultiChart = styled(IconMultiChartSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconNonimg = styled(IconNonimgSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconPageAnaly = styled(IconPageAnalySVG)`
  g {
    path {
      ${default_fill};
    }
  }
  &.focus {
    g {
      path {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    g {
      path {
        ${disabled_fill};
      }
    }
  }
  &.default {
    g {
      path {
        ${default_fill};
      }
    }
  }
`;

export const IconPageview = styled(IconPageviewSVG)`
  path {
    &:last-child {
      ${default_fill};
    }
  }
  &.focus {
    path {
      &:last-child {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    path {
      &:last-child {
        ${disabled_fill};
      }
    }
  }
  &.default {
    path {
      &:last-child {
        ${default_fill};
      }
    }
  }
`;

export const IconPaper = styled(IconPaperSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconPhone = styled(IconPhoneSVG)`
  path {
    &:last-child {
      ${default_fill};
    }
  }
  &.focus {
    path {
      &:last-child {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    path {
      &:last-child {
        ${disabled_fill};
      }
    }
  }
  &.default {
    path {
      &:last-child {
        ${default_fill};
      }
    }
  }
`;

export const IconPlus = styled(IconPlusSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconProfile = styled(IconProfileSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconSearch = styled(IconSearchSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconServiceAnaly = styled(IconServiceAnalySVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconSetting1 = styled(IconSetting1SVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconSetting2 = styled(IconSetting2SVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconSMArrowDown = styled(IconSMArrowDownSVG)`
  g {
    path {
      ${default_fill};
    }
  }
  &.focus {
    g {
      path {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    g {
      path {
        ${disabled_fill};
      }
    }
  }
  &.default {
    g {
      path {
        ${default_fill};
      }
    }
  }
`;

export const IconSMArrowLeft = styled(IconSMArrowLeftSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconSMArrowRight = styled(IconSMArrowRightSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconSMArrowUp = styled(IconSMArrowUpSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconTablet = styled(IconTabletSVG)`
  path {
    &:last-child {
      ${default_fill};
    }
  }
  &.focus {
    path {
      &:last-child {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    path {
      &:last-child {
        ${disabled_fill};
      }
    }
  }
  &.default {
    path {
      &:last-child {
        ${default_fill};
      }
    }
  }
`;

export const IconTextSearch = styled(IconTextSearchSVG)`
  g {
    path {
      ${default_fill};
    }
  }
  &.focus {
    g {
      path {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    g {
      path {
        ${disabled_fill};
      }
    }
  }
  &.default {
    g {
      path {
        ${default_fill};
      }
    }
  }
`;

export const IconTime = styled(IconTimeSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconUserAnaly = styled(IconUserAnalySVG)`
  g {
    path {
      ${default_fill};
    }
  }
  &.focus {
    g {
      path {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    g {
      path {
        ${disabled_fill};
      }
    }
  }
  &.default {
    g {
      path {
        ${default_fill};
      }
    }
  }
`;

export const IconUserflow = styled(IconUserflowSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconWarning = styled(IconWarningSVG)`
  path {
    &:last-child {
      ${default_fill};
    }
  }
  &.focus {
    path {
      &:last-child {
        ${focus_fill};
      }
    }
  }
  &.disabled {
    path {
      &:last-child {
        ${disabled_fill};
      }
    }
  }
  &.default {
    path {
      &:last-child {
        ${default_fill};
      }
    }
  }
`;

export const IconWorld = styled(IconWorldSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconWysiwyg = styled(IconWysiwygSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconUp = styled(IconUPSVG)``;

export const IconDown = styled(IconDownSVG)``;

export const IconBot = styled(IconBotSVG)`
  path {
    fill: black;
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;

export const IconABTest = styled(IconABTestSVG)`
  path {
    ${default_fill};
  }
  &.focus {
    path {
      ${focus_fill};
    }
  }
  &.disabled {
    path {
      ${disabled_fill};
    }
  }
  &.default {
    path {
      ${default_fill};
    }
  }
`;
